import React from 'react';
import Layout from "../components/Layout";
import NavOne from "../components/NavOne";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import TeamSASEdu from '../components/TeamSASEdu';
import Topbar from '../components/Topbar';
const TeamSASEduPage = () => {
    return (
        <Layout pageTitle="Shanti Asiatic School | Team SAS">
            <Topbar />
            <NavOne />
            <PageHeader title="Educators" />
            <TeamSASEdu />
            <Footer />
        </Layout>
    );
};

export default TeamSASEduPage;
