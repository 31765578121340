import React from "react";
import ctabg1 from "../assets/images/patter-1-1.jpg";
import { Link } from "gatsby";
import team1 from "../assets/images/Educator/LethaNair.png";
import team2 from "../assets/images/Educator/NaazninMalik.png";
import team3 from "../assets/images/Educator/ArpitaPurohit.png";
import team4 from "../assets/images/Educator/JonyParekh.png";
import team5 from "../assets/images/Educator/NiharikaSingh.png";
import team6 from "../assets/images/Educator/RashmiKulabkar.png";
import team7 from "../assets/images/Educator/NikitaMehta.png";
import team8 from "../assets/images/Educator/SameerPathan.png";
import team9 from "../assets/images/Educator/SasmitaPanda.png";
import team10 from "../assets/images/Educator/BhavanaThakkar.png";
import team11 from "../assets/images/Educator/JenishModi.png";
import team12 from "../assets/images/Educator/AnkitPancholi.png";
import team13 from "../assets/images/Educator/SakinaChunawala.png";
import team14 from "../assets/images/Educator/KetkiPandit.png";
import team15 from "../assets/images/Educator/IrfanaGodil.png";
import team16 from "../assets/images/Educator/ShreyaKapadia.png";
import team17 from "../assets/images/Educator/KenishKapadia.png";
import team18 from "../assets/images/Educator/RajniSharma.png";
import team19 from "../assets/images/Educator/MansiPatel.png";
import team20 from "../assets/images/Educator/ShikhaShrestha.png";
import team21 from "../assets/images/Educator/ParinazPatel.png";
import team22 from "../assets/images/Educator/AnkitaNaik.png";
import team23 from "../assets/images/Educator/HareshGamit.png";
import team24 from "../assets/images/Educator/RinaBilimoria.png";
import team25 from "../assets/images/Educator/ShahaidPathan.png";
import team26 from "../assets/images/Educator/NikunjLimbachiya.png";
import team27 from "../assets/images/Educator/ShivamPatel.png";
import team28 from "../assets/images/Educator/RachanaKhandar.png";
import team29 from "../assets/images/Educator/MehreenMalik.png";
import team30 from "../assets/images/Educator/VarunPathak.png";
const TeamSASEdu = () => {
  return (
    <div>
    <section
      className="cta-one cta-one__home-one mt-2 mb-2"
      style={{ backgroundImage: `url(${ctabg1})` }}
    >
      <div className="container">
        <h2 className="cta-one__titlese team-details__text">
        We believe that a committed and knowledgeable faculty is the backbone of successful delivery of
        education to children. Each of our school is led by an extremely talented team of teachers and talent
        is not the only attribute they possess. Each one of our teachers have an innate passion, not just to
        teach but also to learn.<br/><br/>
        Our teachers are experts in their fields- in knowledge, in flexibility, in commitment and in
        motivational skills and they realise this passion by engaging with the young people. Being with
        them, living their lives, understanding them, before making them understand the lessons of life. The
        school also believes in developing its teachers and encouraging them to enhance their skills.
        </h2>
        
      </div>
    </section>

    <section className="team-one team-page">
    <div className="container">
    <div className="row">
    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
        <div className="team-one__single">
            <div className="team-one__image">
            <img src={team1} alt="" />
            </div>
            <div className="team-one__content">
            <h2 className="team-one__name">
                <Link to="/teacher-details">LETHA NAIR</Link>
            </h2>
            <p className="team-one__designation">Coordinator - PGT</p>
            {/* <p className="team-one__text">
                There are many varia of passages of lorem.
            </p> */}
            </div>
            {/* <div className="team-one__social">
            <a href="#none">
                <i className="fab fa-twitter"></i>
            </a>
            <a href="#none">
                <i className="fab fa-facebook-square"></i>
            </a>
            <a href="#none">
                <i className="fab fa-pinterest-p"></i>
            </a>
            <a href="#none">
                <i className="fab fa-instagram"></i>
            </a>
            </div> */}
        </div>
        </div>

        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
        <div className="team-one__single">
            <div className="team-one__image">
            <img src={team4} alt="" />
            </div>
            <div className="team-one__content">
            <h2 className="team-one__name">
                <Link to="#">JONY PAREKH</Link>
            </h2>
            <p className="team-one__designation">PGT - PE</p>
            {/* <p className="team-one__text">
                There are many varia of passages of lorem.
            </p> */}
            </div>
            {/* <div className="team-one__social">
            <a href="#none">
                <i className="fab fa-twitter"></i>
            </a>
            <a href="#none">
                <i className="fab fa-facebook-square"></i>
            </a>
            <a href="#none">
                <i className="fab fa-pinterest-p"></i>
            </a>
            <a href="#none">
                <i className="fab fa-instagram"></i>
            </a>
            </div> */}
        </div>
        </div>

        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
        <div className="team-one__single">
            <div className="team-one__image">
            <img src={team5} alt="" />
            </div>
            <div className="team-one__content">
            <h2 className="team-one__name">
                <Link to="#">NIHARIKA SINGH</Link>
            </h2>
            <p className="team-one__designation">PGT - Physics</p>
            {/* <p className="team-one__text">
                There are many varia of passages of lorem.
            </p> */}
            </div>
            {/* <div className="team-one__social">
            <a href="#none">
                <i className="fab fa-twitter"></i>
            </a>
            <a href="#none">
                <i className="fab fa-facebook-square"></i>
            </a>
            <a href="#none">
                <i className="fab fa-pinterest-p"></i>
            </a>
            <a href="#none">
                <i className="fab fa-instagram"></i>
            </a>
            </div> */}
        </div>
        </div>
        
        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
        <div className="team-one__single">
            <div className="team-one__image">
            <img src={team7} alt="" />
            </div>
            <div className="team-one__content">
            <h2 className="team-one__name">
                <Link to="#">Nikita Mehta</Link>
            </h2>
            <p className="team-one__designation">PGT - IP/CS</p>
            {/* <p className="team-one__text">
                There are many varia of passages of lorem.
            </p> */}
            </div>
            {/* <div className="team-one__social">
            <a href="#none">
                <i className="fab fa-twitter"></i>
            </a>
            <a href="#none">
                <i className="fab fa-facebook-square"></i>
            </a>
            <a href="#none">
                <i className="fab fa-pinterest-p"></i>
            </a>
            <a href="#none">
                <i className="fab fa-instagram"></i>
            </a>
            </div> */}
        </div>
        </div>

        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
        <div className="team-one__single">
            <div className="team-one__image">
            <img src={team8} alt="" />
            </div>
            <div className="team-one__content">
            <h2 className="team-one__name">
                <Link to="#">Sameer Pathan</Link>
            </h2>
            <p className="team-one__designation">PGT - SS</p>
            {/* <p className="team-one__text">
                There are many varia of passages of lorem.
            </p> */}
            </div>
            {/* <div className="team-one__social">
            <a href="#none">
                <i className="fab fa-twitter"></i>
            </a>
            <a href="#none">
                <i className="fab fa-facebook-square"></i>
            </a>
            <a href="#none">
                <i className="fab fa-pinterest-p"></i>
            </a>
            <a href="#none">
                <i className="fab fa-instagram"></i>
            </a>
            </div> */}
        </div>
        </div>

        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
        <div className="team-one__single">
            <div className="team-one__image">
            <img src={team9} alt="" />
            </div>
            <div className="team-one__content">
            <h2 className="team-one__name">
                <Link to="#">SASMITA PANDA</Link>
            </h2>
            <p className="team-one__designation">PGT - MATHS</p>
            {/* <p className="team-one__text">
                There are many varia of passages of lorem.
            </p> */}
            </div>
            {/* <div className="team-one__social">
            <a href="#none">
                <i className="fab fa-twitter"></i>
            </a>
            <a href="#none">
                <i className="fab fa-facebook-square"></i>
            </a>
            <a href="#none">
                <i className="fab fa-pinterest-p"></i>
            </a>
            <a href="#none">
                <i className="fab fa-instagram"></i>
            </a>
            </div> */}
        </div>
        </div>
       
        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
        <div className="team-one__single">
            <div className="team-one__image">
            <img src={team10} alt="" />
            </div>
            <div className="team-one__content">
            <h2 className="team-one__name">
                <Link to="#">BHAVANA THAKKAR</Link>
            </h2>
            <p className="team-one__designation">Coordinator - TGT</p>
            {/* <p className="team-one__text">
                There are many varia of passages of lorem.
            </p> */}
            </div>
            {/* <div className="team-one__social">
            <a href="#none">
                <i className="fab fa-twitter"></i>
            </a>
            <a href="#none">
                <i className="fab fa-facebook-square"></i>
            </a>
            <a href="#none">
                <i className="fab fa-pinterest-p"></i>
            </a>
            <a href="#none">
                <i className="fab fa-instagram"></i>
            </a>
            </div> */}
        </div>
        </div>

        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
        <div className="team-one__single">
            <div className="team-one__image">
            <img src={team13} alt="" />
            </div>
            <div className="team-one__content">
            <h2 className="team-one__name">
                <Link to="#">SAKINA CHUNAWALA</Link>
            </h2>
            <p className="team-one__designation">TGT - English</p>
            {/* <p className="team-one__text">
                There are many varia of passages of lorem.
            </p> */}
            </div>
            {/* <div className="team-one__social">
            <a href="#none">
                <i className="fab fa-twitter"></i>
            </a>
            <a href="#none">
                <i className="fab fa-facebook-square"></i>
            </a>
            <a href="#none">
                <i className="fab fa-pinterest-p"></i>
            </a>
            <a href="#none">
                <i className="fab fa-instagram"></i>
            </a>
            </div> */}
        </div>
        </div>

        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
        <div className="team-one__single">
            <div className="team-one__image">
            <img src={team14} alt="" />
            </div>
            <div className="team-one__content">
            <h2 className="team-one__name">
                <Link to="#">KETKI PANDIT</Link>
            </h2>
            <p className="team-one__designation">TGT - Sanskrit</p>
            {/* <p className="team-one__text">
                There are many varia of passages of lorem.
            </p> */}
            </div>
            {/* <div className="team-one__social">
            <a href="#none">
                <i className="fab fa-twitter"></i>
            </a>
            <a href="#none">
                <i className="fab fa-facebook-square"></i>
            </a>
            <a href="#none">
                <i className="fab fa-pinterest-p"></i>
            </a>
            <a href="#none">
                <i className="fab fa-instagram"></i>
            </a>
            </div> */}
        </div>
        </div>

        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
        <div className="team-one__single">
            <div className="team-one__image">
            <img src={team15} alt="" />
            </div>
            <div className="team-one__content">
            <h2 className="team-one__name">
                <Link to="#">IRFANA GODIL</Link>
            </h2>
            <p className="team-one__designation">PRT</p>
            {/* <p className="team-one__text">
                There are many varia of passages of lorem.
            </p> */}
            </div>
            {/* <div className="team-one__social">
            <a href="#none">
                <i className="fab fa-twitter"></i>
            </a>
            <a href="#none">
                <i className="fab fa-facebook-square"></i>
            </a>
            <a href="#none">
                <i className="fab fa-pinterest-p"></i>
            </a>
            <a href="#none">
                <i className="fab fa-instagram"></i>
            </a>
            </div> */}
        </div>
        </div>

        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
        <div className="team-one__single">
            <div className="team-one__image">
            <img src={team17} alt="" />
            </div>
            <div className="team-one__content">
            <h2 className="team-one__name">
                <Link to="#">Jenish Kapadiya</Link>
            </h2>
            <p className="team-one__designation">PRT</p>
            {/* <p className="team-one__text">
                There are many varia of passages of lorem.
            </p> */}
            </div>
            {/* <div className="team-one__social">
            <a href="#none">
                <i className="fab fa-twitter"></i>
            </a>
            <a href="#none">
                <i className="fab fa-facebook-square"></i>
            </a>
            <a href="#none">
                <i className="fab fa-pinterest-p"></i>
            </a>
            <a href="#none">
                <i className="fab fa-instagram"></i>
            </a>
            </div> */}
        </div>
        </div>

        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
        <div className="team-one__single">
            <div className="team-one__image">
            <img src={team18} alt="" />
            </div>
            <div className="team-one__content">
            <h2 className="team-one__name">
                <Link to="#">RAJNI SHARMA</Link>
            </h2>
            <p className="team-one__designation">PRT</p>
            {/* <p className="team-one__text">
                There are many varia of passages of lorem.
            </p> */}
            </div>
            {/* <div className="team-one__social">
            <a href="#none">
                <i className="fab fa-twitter"></i>
            </a>
            <a href="#none">
                <i className="fab fa-facebook-square"></i>
            </a>
            <a href="#none">
                <i className="fab fa-pinterest-p"></i>
            </a>
            <a href="#none">
                <i className="fab fa-instagram"></i>
            </a>
            </div> */}
        </div>
        </div>

        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
        <div className="team-one__single">
            <div className="team-one__image">
            <img src={team19} alt="" />
            </div>
            <div className="team-one__content">
            <h2 className="team-one__name">
                <Link to="#">MANSI PATEL</Link>
            </h2>
            <p className="team-one__designation">PRT</p>
            {/* <p className="team-one__text">
                There are many varia of passages of lorem.
            </p> */}
            </div>
            {/* <div className="team-one__social">
            <a href="#none">
                <i className="fab fa-twitter"></i>
            </a>
            <a href="#none">
                <i className="fab fa-facebook-square"></i>
            </a>
            <a href="#none">
                <i className="fab fa-pinterest-p"></i>
            </a>
            <a href="#none">
                <i className="fab fa-instagram"></i>
            </a>
            </div> */}
        </div>
        </div>

        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
        <div className="team-one__single">
            <div className="team-one__image">
            <img src={team20} alt="" />
            </div>
            <div className="team-one__content">
            <h2 className="team-one__name">
                <Link to="#">SHIKHA KULSHRESTHA</Link>
            </h2>
            <p className="team-one__designation">PRT</p>
            {/* <p className="team-one__text">
                There are many varia of passages of lorem.
            </p> */}
            </div>
            {/* <div className="team-one__social">
            <a href="#none">
                <i className="fab fa-twitter"></i>
            </a>
            <a href="#none">
                <i className="fab fa-facebook-square"></i>
            </a>
            <a href="#none">
                <i className="fab fa-pinterest-p"></i>
            </a>
            <a href="#none">
                <i className="fab fa-instagram"></i>
            </a>
            </div> */}
        </div>
        </div>

        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
        <div className="team-one__single">
            <div className="team-one__image">
            <img src={team22} alt="" />
            </div>
            <div className="team-one__content">
            <h2 className="team-one__name">
                <Link to="#">ANKITA NAIK</Link>
            </h2>
            <p className="team-one__designation">PRT - Gujarati</p>
            {/* <p className="team-one__text">
                There are many varia of passages of lorem.
            </p> */}
            </div>
            {/* <div className="team-one__social">
            <a href="#none">
                <i className="fab fa-twitter"></i>
            </a>
            <a href="#none">
                <i className="fab fa-facebook-square"></i>
            </a>
            <a href="#none">
                <i className="fab fa-pinterest-p"></i>
            </a>
            <a href="#none">
                <i className="fab fa-instagram"></i>
            </a>
            </div> */}
        </div>
        </div>

        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
        <div className="team-one__single">
            <div className="team-one__image">
            <img src={team23} alt="" />
            </div>
            <div className="team-one__content">
            <h2 className="team-one__name">
                <Link to="#">HARESH GAMIT</Link>
            </h2>
            <p className="team-one__designation">Librarian</p>
            {/* <p className="team-one__text">
                There are many varia of passages of lorem.
            </p> */}
            </div>
            {/* <div className="team-one__social">
            <a href="#none">
                <i className="fab fa-twitter"></i>
            </a>
            <a href="#none">
                <i className="fab fa-facebook-square"></i>
            </a>
            <a href="#none">
                <i className="fab fa-pinterest-p"></i>
            </a>
            <a href="#none">
                <i className="fab fa-instagram"></i>
            </a>
            </div> */}
        </div>
        </div>

        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
        <div className="team-one__single">
            <div className="team-one__image">
            <img src={team24} alt="" />
            </div>
            <div className="team-one__content">
            <h2 className="team-one__name">
                <Link to="#">RINA BILIMORIA</Link>
            </h2>
            <p className="team-one__designation">PRT - Art & Craft</p>
            {/* <p className="team-one__text">
                There are many varia of passages of lorem.
            </p> */}
            </div>
            {/* <div className="team-one__social">
            <a href="#none">
                <i className="fab fa-twitter"></i>
            </a>
            <a href="#none">
                <i className="fab fa-facebook-square"></i>
            </a>
            <a href="#none">
                <i className="fab fa-pinterest-p"></i>
            </a>
            <a href="#none">
                <i className="fab fa-instagram"></i>
            </a>
            </div> */}
        </div>
        </div>

        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
        <div className="team-one__single">
            <div className="team-one__image">
            <img src={team27} alt="" />
            </div>
            <div className="team-one__content">
            <h2 className="team-one__name">
                <Link to="#">SHIVAM PATEL</Link>
            </h2>
            <p className="team-one__designation">PRT - Dance</p>
            {/* <p className="team-one__text">
                There are many varia of passages of lorem.
            </p> */}
            </div>
            {/* <div className="team-one__social">
            <a href="#none">
                <i className="fab fa-twitter"></i>
            </a>
            <a href="#none">
                <i className="fab fa-facebook-square"></i>
            </a>
            <a href="#none">
                <i className="fab fa-pinterest-p"></i>
            </a>
            <a href="#none">
                <i className="fab fa-instagram"></i>
            </a>
            </div> */}
        </div>
        </div>

        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
        <div className="team-one__single">
            <div className="team-one__image">
            <img src={team29} alt="" />
            </div>
            <div className="team-one__content">
            <h2 className="team-one__name">
                <Link to="#">MEHREEN MALIK</Link>
            </h2>
            <p className="team-one__designation">SPL Educator</p>
            {/* <p className="team-one__text">
                There are many varia of passages of lorem.
            </p> */}
            </div>
            {/* <div className="team-one__social">
            <a href="#none">
                <i className="fab fa-twitter"></i>
            </a>
            <a href="#none">
                <i className="fab fa-facebook-square"></i>
            </a>
            <a href="#none">
                <i className="fab fa-pinterest-p"></i>
            </a>
            <a href="#none">
                <i className="fab fa-instagram"></i>
            </a>
            </div> */}
        </div>
        </div>

        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
        <div className="team-one__single">
            <div className="team-one__image">
            <img src={team30} alt="" />
            </div>
            <div className="team-one__content">
            <h2 className="team-one__name">
                <Link to="#">VARUN PATHAK</Link>
            </h2>
            <p className="team-one__designation">TGT - Hindi</p>
            {/* <p className="team-one__text">
                There are many varia of passages of lorem.
            </p> */}
            </div>
            {/* <div className="team-one__social">
            <a href="#none">
                <i className="fab fa-twitter"></i>
            </a>
            <a href="#none">
                <i className="fab fa-facebook-square"></i>
            </a>
            <a href="#none">
                <i className="fab fa-pinterest-p"></i>
            </a>
            <a href="#none">
                <i className="fab fa-instagram"></i>
            </a>
            </div> */}
        </div>
        </div>
    </div>
    </div>
    </section>
</div>
  );
};

export default TeamSASEdu;
